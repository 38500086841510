import BaseModel from './BaseModel'
import Flight from './Flight'
import Plane from './Plane'

export default class Pilot extends BaseModel {
  static entity = 'pilots'
  static backendModel = 'pilot'

  static primaryKey = 'id'
  static fieldsToEmit = ['id', 'name', 'refueling', 'flightId', 'planeId']
  static relationshipMappings = [
    {
      ref: 'flightId',
      direct: 'flight',
    },
    {
      ref: 'planeId',
      direct: 'plane',
    },
  ]

  static fields() {
    return {
      id: this.uid(),
      name: this.string(),
      refueling: this.boolean(false),
      flight: this.belongsTo(Flight, 'flightId'),
      flightId: this.uid().nullable(),
      plane: this.belongsTo(Plane, 'planeId'),
      planeId: this.uid().nullable(),
    }
  }
}
