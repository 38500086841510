import { render, staticRenderFns } from "./ShipFormationBox.vue?vue&type=template&id=686f5b64&scoped=true"
import script from "./ShipFormationBox.vue?vue&type=script&lang=js"
export * from "./ShipFormationBox.vue?vue&type=script&lang=js"
import style0 from "./ShipFormationBox.vue?vue&type=style&index=0&id=686f5b64&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "686f5b64",
  null
  
)

export default component.exports