<template>
  <hsc-menu-style-black>
    <hsc-menu-context-menu :menuZIndex="999" ref="contextMenu">
      <div class="pilot" :class="{ refueling: pilot.refueling }" @click="onClick">
        <div class="top row">
          <p class="name">{{ pilot.name }}</p>
          <p class="plane">{{ pilot.plane && pilot.plane.name }}</p>
        </div>
        <div class="middle row"></div>
        <div class="bottom row">
          <div class="fuel" v-if="pilot.plane" :style="`width: ${pilot.plane.fuel * 100}%`"></div>
        </div>
      </div>
      <template v-if="this.userLoggedIn" slot="contextmenu">
        <hsc-menu-item label="Assign to ship-formation">
          <hsc-menu-item label="New ship-formation"
                        :disabled="getCreatableFlights().length <= 0" >
            <hsc-menu-item v-for="flightType in flightTypes"
                          :key="flightType" :label="flightType"
                          :disabled="getCreatableFlights(flightType).length <= 0"
                          :sync="true">
              <hsc-menu-item v-for="flight in getCreatableFlights(flightType)"
                            :key="flight.name" :label="flight.name"
                            @click="createNewFlight(flight)"
                            :sync="true" />
            </hsc-menu-item>
          </hsc-menu-item>
          <hsc-menu-separator v-if="flights.length > 0" />
          <hsc-menu-item v-for="flight in flights"
                        :key="flight.$id" :label="flight.name"
                        @click="assignFlight(flight.$id)"
                        :disabled="pilot.flightId === flight.$id" :sync="true"/>
        </hsc-menu-item>
        <hsc-menu-item label="Unassign" :disabled="pilot.flightId === null"
                      @click="unassignFlight()" :sync="true"/>
        <hsc-menu-separator />
        <hsc-menu-item label="Send to refueling" :disabled="pilot.refueling"
                      @click="toggleRefueling(pilot)" :sync="true"/>
        <hsc-menu-item label="Refueling completed" :disabled="!pilot.refueling"
                      @click="toggleRefueling(pilot)" :sync="true"/>
      </template>
    </hsc-menu-context-menu>
  </hsc-menu-style-black>
</template>

<script>
import { mapGetters } from 'vuex'
import Flight from '../store/models/Flight'
import Pilot from '../store/models/Pilot'
import Unit from '../store/models/Unit'

export default {
  name: 'PilotBox',
  data() {
    return {
      defaultFlights: {
        'MH-6': [
          {
            name: 'BADGER-1',
            frequency: 31.1,
          },
          {
            name: 'BADGER-2',
            frequency: 31.2,
          },
          {
            name: 'BADGER-3',
            frequency: 31.3,
          },
          {
            name: 'BADGER-4',
            frequency: 31.4,
          },
          {
            name: 'BADGER-5',
            frequency: 31.5,
          },
          {
            name: 'BADGER-6',
            frequency: 31.6,
          },
          {
            name: 'BADGER-7',
            frequency: 31.7,
          },
          {
            name: 'BADGER-8',
            frequency: 31.8,
          },
          {
            name: 'BADGER-9',
            frequency: 31.9,
          },
        ],
        'MH-60': [
          {
            name: 'BANSHEE-1',
            frequency: 32.1,
          },
          {
            name: 'BANSHEE-2',
            frequency: 32.2,
          },
          {
            name: 'BANSHEE-3',
            frequency: 32.3,
          },
          {
            name: 'BANSHEE-4',
            frequency: 32.4,
          },
          {
            name: 'BANSHEE-5',
            frequency: 32.5,
          },
          {
            name: 'BANSHEE-6',
            frequency: 32.6,
          },
          {
            name: 'BANSHEE-7',
            frequency: 32.7,
          },
          {
            name: 'BANSHEE-8',
            frequency: 32.8,
          },
          {
            name: 'BANSHEE-9',
            frequency: 32.9,
          },
        ],
        'MH-47': [
          {
            name: 'BANDWAGON-1',
            frequency: 33.1,
          },
          {
            name: 'BANDWAGON-2',
            frequency: 33.2,
          },
          {
            name: 'BANDWAGON-3',
            frequency: 33.3,
          },
          {
            name: 'BANDWAGON-4',
            frequency: 33.4,
          },
          {
            name: 'BANDWAGON-5',
            frequency: 33.5,
          },
          {
            name: 'BANDWAGON-6',
            frequency: 33.6,
          },
          {
            name: 'BANDWAGON-7',
            frequency: 33.7,
          },
          {
            name: 'BANDWAGON-8',
            frequency: 33.8,
          },
          {
            name: 'BANDWAGON-9',
            frequency: 33.9,
          },
        ],
        'AH-6': [
          {
            name: 'DAWNFALL-1',
            frequency: 34.1,
          },
          {
            name: 'DAWNFALL-2',
            frequency: 34.2,
          },
          {
            name: 'DAWNFALL-3',
            frequency: 34.3,
          },
          {
            name: 'DAWNFALL-4',
            frequency: 34.4,
          },
          {
            name: 'DAWNFALL-5',
            frequency: 34.5,
          },
          {
            name: 'DAWNFALL-6',
            frequency: 34.6,
          },
          {
            name: 'DAWNFALL-7',
            frequency: 34.7,
          },
          {
            name: 'DAWNFALL-8',
            frequency: 34.8,
          },
          {
            name: 'DAWNFALL-9',
            frequency: 34.9,
          },
        ],
        'AH-60 DAP': [
          {
            name: 'DRAGON-1',
            frequency: 35.1,
          },
          {
            name: 'DRAGON-2',
            frequency: 35.2,
          },
          {
            name: 'DRAGON-3',
            frequency: 35.3,
          },
          {
            name: 'DRAGON-4',
            frequency: 35.4,
          },
          {
            name: 'DRAGON-5',
            frequency: 35.5,
          },
          {
            name: 'DRAGON-6',
            frequency: 35.6,
          },
          {
            name: 'DRAGON-7',
            frequency: 35.7,
          },
          {
            name: 'DRAGON-8',
            frequency: 35.8,
          },
          {
            name: 'DRAGON-9',
            frequency: 35.9,
          },
        ],
        'AH-64': [
          {
            name: 'DEMON-1',
            frequency: 36.1,
          },
          {
            name: 'DEMON-2',
            frequency: 36.2,
          },
          {
            name: 'DEMON-3',
            frequency: 36.3,
          },
          {
            name: 'DEMON-4',
            frequency: 36.4,
          },
          {
            name: 'DEMON-5',
            frequency: 36.5,
          },
          {
            name: 'DEMON-6',
            frequency: 36.6,
          },
          {
            name: 'DEMON-7',
            frequency: 36.7,
          },
          {
            name: 'DEMON-8',
            frequency: 36.8,
          },
          {
            name: 'DEMON-9',
            frequency: 36.9,
          },
        ],
        'Medium Transport': [
          {
            name: 'ROOSTER-1',
            frequency: 37.1,
          },
          {
            name: 'ROOSTER-2',
            frequency: 37.2,
          },
          {
            name: 'ROOSTER-3',
            frequency: 37.3,
          },
          {
            name: 'ROOSTER-4',
            frequency: 37.4,
          },
          {
            name: 'ROOSTER-5',
            frequency: 37.5,
          },
          {
            name: 'ROOSTER-6',
            frequency: 37.6,
          },
          {
            name: 'ROOSTER-7',
            frequency: 37.7,
          },
          {
            name: 'ROOSTER-8',
            frequency: 37.8,
          },
          {
            name: 'ROOSTER-9',
            frequency: 37.9,
          },
        ],
        'C-130': [
          {
            name: 'ELVIS-1',
            frequency: 38.1,
          },
          {
            name: 'ELVIS-2',
            frequency: 38.2,
          },
          {
            name: 'ELVIS-3',
            frequency: 38.3,
          },
          {
            name: 'ELVIS-4',
            frequency: 38.4,
          },
          {
            name: 'ELVIS-5',
            frequency: 38.5,
          },
          {
            name: 'ELVIS-6',
            frequency: 38.6,
          },
          {
            name: 'ELVIS-7',
            frequency: 38.7,
          },
          {
            name: 'ELVIS-8',
            frequency: 38.8,
          },
          {
            name: 'ELVIS-9',
            frequency: 38.9,
          },
        ],
        Osprey: [
          {
            name: 'EMPIRE-1',
            frequency: 39.1,
          },
          {
            name: 'EMPIRE-2',
            frequency: 39.2,
          },
          {
            name: 'EMPIRE-3',
            frequency: 39.3,
          },
          {
            name: 'EMPIRE-4',
            frequency: 39.4,
          },
          {
            name: 'EMPIRE-5',
            frequency: 39.5,
          },
          {
            name: 'EMPIRE-6',
            frequency: 39.6,
          },
          {
            name: 'EMPIRE-7',
            frequency: 39.7,
          },
          {
            name: 'EMPIRE-8',
            frequency: 39.8,
          },
          {
            name: 'EMPIRE-9',
            frequency: 39.9,
          },
        ],
        'A-10': [
          {
            name: 'CRUSTY-1',
            frequency: 41.1,
          },
          {
            name: 'CRUSTY-2',
            frequency: 41.2,
          },
          {
            name: 'CRUSTY-3',
            frequency: 41.3,
          },
          {
            name: 'CRUSTY-4',
            frequency: 41.4,
          },
          {
            name: 'CRUSTY-5',
            frequency: 41.5,
          },
          {
            name: 'CRUSTY-6',
            frequency: 41.6,
          },
          {
            name: 'CRUSTY-7',
            frequency: 41.7,
          },
          {
            name: 'CRUSTY-8',
            frequency: 41.8,
          },
          {
            name: 'CRUSTY-9',
            frequency: 41.9,
          },
        ],
        'F-15': [
          {
            name: 'CATFISH-1',
            frequency: 42.1,
          },
          {
            name: 'CATFISH-2',
            frequency: 42.2,
          },
          {
            name: 'CATFISH-3',
            frequency: 42.3,
          },
          {
            name: 'CATFISH-4',
            frequency: 42.4,
          },
          {
            name: 'CATFISH-5',
            frequency: 42.5,
          },
          {
            name: 'CATFISH-6',
            frequency: 42.6,
          },
          {
            name: 'CATFISH-7',
            frequency: 42.7,
          },
          {
            name: 'CATFISH-8',
            frequency: 42.8,
          },
          {
            name: 'CATFISH-9',
            frequency: 42.9,
          },
        ],
        'F-18': [
          {
            name: 'COWBOY-1',
            frequency: 43.1,
          },
          {
            name: 'COWBOY-2',
            frequency: 43.2,
          },
          {
            name: 'COWBOY-3',
            frequency: 43.3,
          },
          {
            name: 'COWBOY-4',
            frequency: 43.4,
          },
          {
            name: 'COWBOY-5',
            frequency: 43.5,
          },
          {
            name: 'COWBOY-6',
            frequency: 43.6,
          },
          {
            name: 'COWBOY-7',
            frequency: 43.7,
          },
          {
            name: 'COWBOY-8',
            frequency: 43.8,
          },
          {
            name: 'COWBOY-9',
            frequency: 43.9,
          },
        ],
        'F-35B': [
          {
            name: 'CANYON-1',
            frequency: 44.1,
          },
          {
            name: 'CANYON-2',
            frequency: 44.2,
          },
          {
            name: 'CANYON-3',
            frequency: 44.3,
          },
          {
            name: 'CANYON-4',
            frequency: 44.4,
          },
          {
            name: 'CANYON-5',
            frequency: 44.5,
          },
          {
            name: 'CANYON-6',
            frequency: 44.6,
          },
          {
            name: 'CANYON-7',
            frequency: 44.7,
          },
          {
            name: 'CANYON-8',
            frequency: 44.8,
          },
          {
            name: 'CANYON-9',
            frequency: 44.9,
          },
        ],
      },
    }
  },
  props: {
    pilot: {
      type: Object,
      required: true,
    },
  },
  computed: {
    ...mapGetters(['userLoggedIn']),
    flights() {
      return Flight.all()
    },
    flightTypes() {
      return Object.keys(this.defaultFlights)
    },
  },
  methods: {
    getCreatableFlights(type) {
      const createdFlightsNames = Flight.all().map(f => f.name)
      if (type) {
        return this.defaultFlights[type].filter(
          f => !createdFlightsNames.includes(f.name),
        )
      }

      let allShipFormationNames = []
      Object.keys(this.defaultFlights).forEach((flightType) => {
        const names = this.defaultFlights[flightType].map(
          shipFormation => shipFormation.name,
        )
        allShipFormationNames = allShipFormationNames.concat(names)
      })
      return allShipFormationNames.filter(f => !createdFlightsNames.includes(f))
    },
    onClick(event) {
      this.$emit('click', event)
      if (
        typeof window.orientation !== 'undefined' ||
        navigator.userAgent.indexOf('IEMobile') !== -1
      ) {
        this.$refs.contextMenu.openMenu(event)
      }
    },
    assignFlight(flightId) {
      Pilot.update({
        where: this.pilot.$id,
        data: {
          flightId,
        },
        shouldEmit: true,
      })
    },
    unassignFlight() {
      Pilot.update({
        where: this.pilot.$id,
        data: {
          flightId: null,
        },
        shouldEmit: true,
      })
    },
    async createNewFlight(flight) {
      const baseUnit = Unit.query().where('position', 'BASE').first();
      const entities = await Flight.insert({
        data: Object.assign(flight, {
          unitId: baseUnit.$id,
          inMovement: true,
          movementStartTime: new Date().toISOString(),
          movementEndTime: null,
        }),
        shouldEmit: true,
      })

      Pilot.update({
        where: this.pilot.$id,
        data: {
          flightId: entities.flights[0].$id,
        },
        shouldEmit: true,
      })
    },
    toggleRefueling(pilot) {
      Pilot.update({
        where: pilot.$id,
        data: {
          refueling: !pilot.refueling
        },
        shouldEmit: true,
      })
    },
  },
}
</script>

<style scoped>
.pilot {
  min-width: 300px;
  background: #003000;
  border: 3px solid #7BE580;
  margin-bottom: 5px;
  position: relative;
  overflow: hidden;
  z-index: 1;
}

.refueling::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: calc(100% + 100px);
  height: 100%;
  background: repeating-linear-gradient(45deg, #313131 25%, #313131 50%, #c44 50%, #c44 75%);
  background-size: 100px 100px;
  -webkit-animation: stripeTransform 2s linear infinite;
  animation: stripeTransform 2s linear infinite;
  z-index: -1;
}

.refueling::after {
  content: "";
  top: 0;
  left: 0;
  position: absolute;
  width: 100%;
  height: 100%;
  background: radial-gradient(ellipse at center, rgba(27, 39, 53, 0) 0%, #090a0f 100%);
  z-index: -1;
}

@-webkit-keyframes stripeTransform {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(calc(100px * -1));
  }
}

@keyframes stripeTransform {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(calc(100px * -1));
  }
}
@-webkit-keyframes stripeBackgroundPosition {
  0% {
    background-position: 0 0;
  }
  100% {
    background-position: calc(100px * -1) 0;
  }
}
@keyframes stripeBackgroundPosition {
  0% {
    background-position: 0 0;
  }
  100% {
    background-position: calc(100px * -1) 0;
  }
}

.row {
  width: 100%;
  display: flex;
  justify-content: space-around;
  box-sizing: border-box;
}

.row .name {
  color: #fff;
}

.row p {
  margin: 0;
  font-weight: bold;
  flex-grow: 1;
  text-align: center;
}

.row p:first-child {
  text-align: left;
}

.row p:last-child {
  text-align: right;
}

.row.top {
  padding: 7px 10px 0px 10px;
}

.row.middle {
  padding: 5px;
}

.row.bottom {
  display: block;
}

.fuel {
  height: 10px;
  background: #7BE580;
  align-self: flex-start;
  transition: width 0.2s ease-out;
}
</style>
