<template>
  <div id="Home">
    <div>
      <div class="pilotContainer">
        <pilot-box class="pilotBox" v-for="pilot in pilotsWithoutShipFormations"
                  :key="pilot.$id" :pilot="pilot"/>
      </div>
      <div class="unitContainer">
        <unit-box v-for="unit in units" :key="unit.$id"
                :unit="unit" :areaName="unit.position"
                :showNoShipFormationsText="getShipFormationsOfUnit(unit.$id).length <= 0">
            <ship-formation-box class="shipFormationBox"
                                v-for="shipFormation in getShipFormationsOfUnit(unit.$id)"
                                :key="shipFormation.$id"
                                :flight="shipFormation"
                                :showNoPilotsText="
                                  getPilotsOfShipFormation(shipFormation.$id).length <= 0">
              <pilot-box v-for="pilot in getPilotsOfShipFormation(shipFormation.$id)"
                        :key="pilot.$id"
                        :pilot="pilot"/>
            </ship-formation-box>
        </unit-box>
      </div>
    </div>
  </div>
</template>

<script>
import Pilot from '../store/models/Pilot'
import Flight from '../store/models/Flight'
import Unit from '../store/models/Unit'
import PilotBox from '../components/PilotBox'
import UnitBox from '../components/UnitBox'
import ShipFormationBox from '../components/ShipFormationBox'
import StateOverlay from '../components/StateOverlay'

export default {
  name: 'Home',
  data() {
    return {
      cct: {
        name: 'CCT',
        groundFrequency: null,
        airFrequency: '78',
      },
    }
  },
  components: { PilotBox, UnitBox, ShipFormationBox, StateOverlay },
  computed: {
    pilots() {
      return Pilot.query().with('plane').get()
    },
    pilotsWithoutShipFormations() {
      return Pilot.query().with('plane').hasNot('flight').get()
    },
    shipFormations() {
      return Flight.all()
    },
    units() {
      return Unit.all()
    },
  },
  methods: {
    getShipFormationsOfUnit(unitId) {
      return Flight.query().whereHas('unit', (query) => {
        query.whereId(unitId)
      }).get()
    },
    getPilotsOfShipFormation(flightId) {
      return Pilot.query().whereHas('flight', (query) => {
        query.whereId(flightId)
      }).with('plane').get()
    },
  },
}
</script>

<style scoped>
.pilotContainer {
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
}

.pilotContainer .pilotBox {
  margin: 5px;
  box-sizing: border-box;
}

.unitContainer {
  margin-top: 20px;
}

.shipFormationBox:not(:last-child) {
  margin-bottom: 10px;
}
</style>
