import io from 'socket.io-client'

let instance = null

export default function client() {
  if (!instance) {
    instance = io(process.env.VUE_APP_URL, { transports: ['websocket'], path: `${process.env.VUE_APP_NAMESPACE}/socket.io` })
  }
  return instance
}
