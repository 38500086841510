import { Model } from '@vuex-orm/core'
import Utils from '../../utils/Utils'

export default class BaseModel extends Model {
  static getDirectRelationship(data) {
    const json = Utils.copyObject(data)

    if (this.relationshipMappings) {
      this.relationshipMappings.forEach((mapping) => {
        const refId = json[mapping.ref]
        if (typeof refId !== 'undefined') {
          json[mapping.direct] = json[mapping.ref]
          delete json[mapping.ref]
        }
      })
    }

    return json
  }

  static getRefRelationship(data) {
    const json = Utils.copyObject(data)

    if (this.relationshipMappings) {
      this.relationshipMappings.forEach((mapping) => {
        const directId = json[mapping.direct]
        if (typeof directId !== 'undefined') {
          json[mapping.ref] = json[mapping.direct]
          delete json[mapping.direct]
        }
      })
    }

    return json
  }
}
