import Vue from 'vue'
import Router from 'vue-router'
import Home from '../pages/Home'
import WPN from '../pages/WPN'

Vue.use(Router)

export default new Router({
  mode: 'history',
  routes: [
    {
      path: `${process.env.VUE_APP_NAMESPACE}/`,
      name: 'Home',
      component: Home,
    },
    {
      path: `${process.env.VUE_APP_NAMESPACE}/wpn`,
      name: 'WPN',
      component: WPN,
    },
  ],
})
