import BaseModel from './BaseModel'
import Flight from './Flight'

export const POSITION = {
  BASE: 'BASE',
  MARSHALLING_AREA: 'MARSHALLING_AREA',
  BATTLE_POSITION: 'BATTLE_POSITION'
}

export default class Unit extends BaseModel {
  static entity = 'units'
  static backendModel = 'unit'

  static primaryKey = 'id'
  static fieldsToEmit = ['id', 'name', 'airFrequency', 'groundFrequency', 'laserCode', 'targetColor']

  static fields() {
    return {
      id: this.uid(),
      name: this.string(),
      airFrequency: this.number(),
      groundFrequency: this.number(),
      laserCode: this.number().nullable(),
      targetColor: this.string().nullable(),
      flights: this.hasMany(Flight, 'unitId'),
      position: this.string(POSITION.BASE)
    }
  }
}
