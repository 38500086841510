<template>
  <hsc-menu-style-black>
    <div class="flight"
        :class="{ inMovement: flight.inMovement }">
        <hsc-menu-context-menu :menuZIndex="999" ref="contextMenu">
          <div class="title" @click="openContextMenu">
            <p class="movementTime">
              {{ timer }}
            </p>
            <p>{{ flight.name }}</p>
            <div class="confirmButton">
              <button v-if="this.userLoggedIn" v-show="flight.inMovement"
                    @click="confirmArrival">Confirm arrival</button>
            </div>
          </div>
          <template v-if="this.userLoggedIn" slot="contextmenu">
            <hsc-menu-item label="Move to base"
              @click="assignToUnit(baseUnit.$id)"
              :disabled="flight.unitId === baseUnit.$id" :sync="true" />
            <hsc-menu-item label="Move to marshalling area"
              @click="assignToUnit(marshallingUnit.$id)"
              :disabled="flight.unitId === marshallingUnit.$id" :sync="true" />
            <hsc-menu-separator />
            <hsc-menu-item label="Assign to battle position">
              <hsc-menu-item v-for="unit in battlePositionUnits" :key="unit.$id"
                          :label="unit.name"
                          @click="assignToUnit(unit.$id)"
                          :disabled="unit.$id === flight.unitId"
                          :sync="true" />
            </hsc-menu-item>
            <hsc-menu-separator />
            <hsc-menu-item label="Confirm arrival"
              @click="confirmArrival"
              :disabled="!flight.inMovement"
              :sync="true" />
            <hsc-menu-item label="Delete" @click="deleteFlight"
              :sync="true" />
          </template>
        </hsc-menu-context-menu>
        <div class="pilots">
          <p class="noPilotsText" v-if="showNoPilotsText">No pilots</p>
          <slot></slot>
        </div>
        <div class="information">
          <input @focus="$event.target.select()" type="text"
            :disabled="!this.userLoggedIn"
            @blur="saveInformation"
            @keyup.enter="saveInformation"
            placeholder="Status"
            name="status" value="">
          <input @focus="$event.target.select()" type="text"
            :disabled="!this.userLoggedIn"
            @blur="saveFrequency"
            @keyup.enter="saveFrequency"
            placeholder="Frequency"
            name="radioFrequency" :value="flight.frequency">
          <input @focus="$event.target.select()" type="text"
            :disabled="!this.userLoggedIn"
            @blur="saveInformation"
            @keyup.enter="saveInformation"
            placeholder="Task"
            name="task" value="">
        </div>
      </div>
  </hsc-menu-style-black>
</template>

<script>
import { mapGetters } from 'vuex'
import Unit, { POSITION } from '../store/models/Unit'
import Flight from '../store/models/Flight'
import Pilot from '../store/models/Pilot'

export default {
  name: 'ShipFormationBox',
  data() {
    return {
      timerInterval: undefined,
      timer: this.getTimeDiff()
    }
  },
  props: {
    flight: {
      type: Object,
      required: true,
    },
    showNoPilotsText: {
      type: Boolean,
      default() {
        return false
      },
    },
  },
  beforeDestroy() {
    this.clearTimerInterval()
  },
  watch: {
    flight: function (newVal, oldVal) {
      if (oldVal.movementStartTime !== newVal.movementStartTime) {
        this.setTimerInterval()
      }

      if (newVal.movementEndTime) {
        this.clearTimerInterval()
      }
    }
  },
  mounted() {
    // We need to check the movementStartTime in mounted as the component is destoryed/recreated upon unit assignment
    if (this.flight.movementStartTime && !this.flight.movementEndTime) {
      this.setTimerInterval()
    }
  },
  computed: {
    ...mapGetters(['userLoggedIn']),
    battlePositionUnits() {
      return Unit.query().where('position', POSITION.BATTLE_POSITION).all()
    },
    baseUnit() {
      return Unit.query().where('position', POSITION.BASE).first();
    },
    marshallingUnit() {
      return Unit.query().where('position', POSITION.MARSHALLING_AREA).first();
    }
  },
  methods: {
    setTimerInterval() {
      this.clearTimerInterval()
      this.timerInterval = setInterval(() => {
        this.timer = this.getTimeDiff()
      }, 200)
    },
    clearTimerInterval() {
      if (this.timerInterval) {
        clearInterval(this.timerInterval)
      }
    },
    getTimeDiff() {
      // This cannot be a computed property as it isn't updated due to vue js caching system
      if (this.flight.movementStartTime) {
        const startDate = new Date(this.flight.movementStartTime)
        const endDate = this.flight.movementEndTime ? new Date(this.flight.movementEndTime) : new Date()
        const diff = new Date(endDate - startDate)
        const minutes = diff.getMinutes().toString().padStart(2, '0')
        const seconds = diff.getSeconds().toString().padStart(2, '0')
        return `${minutes}:${seconds}`
      } else {
        return ''
      }
    },
    confirmArrival() {
      Flight.update({
        where: this.flight.$id,
        data: {
          inMovement: false,
          movementEndTime: new Date().toISOString()
        },
        shouldEmit: true,
      })
    },
    async deleteFlight() {
      const { pilots } = Flight.query().with('pilots').find(this.flight.$id)
      await Flight.delete({
        id: this.flight.$id,
        shouldEmit: true,
      })

      pilots.forEach((pilot) => {
        Pilot.update({
          where: pilot.$id,
          data: {
            flightId: null,
          },
          shouldEmit: true,
        })
      })
    },
    assignToUnit(unitId) {
      Flight.update({
        where: this.flight.$id,
        data: {
          unitId: unitId,
          inMovement: true,
          movementStartTime: new Date().toISOString(),
          movementEndTime: null,
        },
        shouldEmit: true,
      })
    },
    saveInformation(event) {
      event.preventDefault()
      event.srcElement.blur()
      // Do nothing for now
    },
    saveFrequency(event) {
      event.preventDefault()
      event.srcElement.blur()
      const frequency = parseFloat(event.srcElement.value)

      if (Number.isNaN(frequency)) {
        alert("Frequency needs to be a valid number")
      } else {
        Flight.update({
          where: this.flight.$id,
          data: {
            frequency,
          },
          shouldEmit: true,
        })
      }
    },
    openContextMenu(event) {
      if (event.srcElement.type === 'submit') return
      if (
        typeof window.orientation !== 'undefined' ||
        navigator.userAgent.indexOf('IEMobile') !== -1
      ) {
        this.$refs.contextMenu.openMenu(event)
      }
    },
  },
}
</script>

<style scoped>
::placeholder {
    color: #757575;
}

input[type="text"] {
  background: transparent;
  border: none;
  color: #7BE580;
  font-weight: bold;
  font-size: 16px;
  font-family: "Avenir", Helvetica, Arial, sans-serif;
  text-align: center;
  outline: none;
  min-width: 0px;
}

input[name="status"] {
  text-align: left;
}

input[name="task"] {
  text-align: right;
}

.title {
  display: flex;
  justify-content: space-between;
}

.title .movementTime {
  width: 100px;
  color: yellow;
  text-align: left;
  flex-grow: 0;
}

.title .confirmButton {
  flex-grow: 0;
  width: 100px;
}

.title button {
  border: none;
  width: 100%;
  height: 100%;
  background: #757575;
  color: #232323;
  font-family: "Avenir", Helvetica, Arial, sans-serif;
  font-weight: bold;
  font-size: 12px;
  cursor: pointer;
}

.flight {
  width: 350px;
  border: 3px solid #7BE580;
  display: flex;
  justify-content: center;
  flex-direction: column;
  background: #232323;
  padding: 3px;
}

.inMovement {
  border: none;
  background-image:
    linear-gradient(90deg, yellow 50%, transparent 50%),
    linear-gradient(90deg, yellow 50%, transparent 50%),
    linear-gradient(0deg, yellow 50%, transparent 50%),
    linear-gradient(0deg, yellow 50%, transparent 50%);
  background-repeat: repeat-x, repeat-x, repeat-y, repeat-y;
  background-size: 15px 3px, 15px 3px, 3px 15px, 3px 15px;
  background-position: left top, right bottom, left bottom, right   top;
  animation: border-animation 1s infinite linear;
  padding: 6px;
}

@keyframes border-animation {
  0% {
    background-position: left top, right bottom, left bottom, right   top;
  }
  100% {
    background-position: left 15px top, right 15px bottom , left bottom 15px , right   top 15px;
  }
}

.flight .title {
  color: #fff;
}

.flight .information {
  display: flex;
  justify-content: space-between;
}

.flight .pilots {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  margin: 5px 0px;
}

.flight p {
  margin: 0;
  font-weight: bold;
  flex-grow: 1;
  text-align: center;
}

.noPilotsText {
  color: #7BE580;
  font-weight: bold;
  opacity: 0.3;
  text-transform: uppercase;
  padding: 10px 0;
  user-select: none;
}
</style>
