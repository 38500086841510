<template>
  <div class="unit">
    <div class="information td">
      <h2 class="title">{{ unit.name }}</h2>
      <ul class="classifications">
        <li v-if="unit.groundFrequency">
          <strong>Ground freq.: </strong>{{ unit.groundFrequency }} MHz
        </li>
        <li v-if="unit.airFrequency">
          <strong>Air freq.: </strong>{{ unit.airFrequency }} MHz
        </li>
        <li v-if="unit.laserCode">
          <strong>Laser code: </strong>{{ unit.laserCode }}
        </li>
        <li v-if="unit.targetColor">
          <strong>Target color: </strong>{{ unit.targetColor }}
        </li>
      </ul>
    </div>
    <div class="shipFormationArea td">
      <div class="areaName">
        <strong>{{ areaName }}</strong>
      </div>
      <div class="shipFormationSlot">
        <p class="noShipFormationsText" v-if="showNoShipFormationsText">No ship formations</p>
        <slot></slot>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'UnitBox',
  props: {
    unit: {
      type: Object,
      required: true,
    },
    areaName: {
      type: String,
    },
    showNoShipFormationsText: {
      type: Boolean,
      default() {
        return false
      },
    },
  },
}
</script>

<style scoped>
.unit {
  display: flex;
  justify-content: flex-start;
  margin: 5px;
  border: 3px solid #7BE580;
  box-sizing: border-box;
}

.unit .td:not(:first-child) {
  border-left: 3px solid #7BE580;
}

.information {
  width: 10%;
  min-width: 250px;
  background: #003000;
  color: #fff;
  padding: 10px;
}

.title {
  margin: 0;
}

.classifications {
  list-style: none;
  padding: 0;
  margin: 0;
  font-size: 0.85em;
}

.td {
  box-sizing: border-box;
}

.shipFormationArea {
  flex: 1;
  position: relative;
  overflow: hidden;
}

.shipFormationSlot {
  padding: 5px 10px;
  overflow-x: auto;
  box-sizing: border-box;
  display: flex;
  flex-wrap: wrap;
}

.shipFormationSlot > div:not(:last-child) {
  margin-right: 10px;
}

.areaName {
  position: absolute;
  right: 0;
  top: 0;
  background: #7BE580;
  padding: 2px 5px 2px 5px;
  color: #000;
  font-size: 0.85em;
}

.noShipFormationsText {
  color: #7BE580;
  font-weight: bold;
  opacity: 0.3;
  text-transform: uppercase;
  padding: 10px 0;
  user-select: none;
  flex: 1;
  text-align: center;
}

@media (max-width: 950px) {
  .unit {
    flex-direction: column;
    margin-bottom: 30px;
  }

  .td {
    min-height: 60px;
    width: 100%;
    border-left: none !important;
    flex-direction: row;
    flex-wrap: nowrap;
  }

  .td:not(:last-child) {
    border-bottom: 3px solid #7BE580;
  }

  .areaName {
    left: 0;
  }

  .shipFormationSlot {
    margin-top: 25px;
    flex-wrap: nowrap;
  }
}
</style>
