import BaseModel from './BaseModel'
import Pilot from './Pilot'
import Unit from './Unit'

export default class Flight extends BaseModel {
  static entity = 'flights'
  static backendModel = 'flight'

  static primaryKey = 'id'
  static fieldsToEmit = ['id', 'name', 'position', 'inMovement', 'movementStartTime', 'movementEndTime', 'frequency', 'unitId']
  static relationshipMappings = [
    {
      ref: 'unitId',
      direct: 'unit',
    },
  ]

  static fields() {
    return {
      id: this.uid(),
      name: this.string(),
      position: this.string('-'),
      inMovement: this.boolean(false),
      movementStartTime: this.string().nullable(),
      movementEndTime: this.string().nullable(),
      frequency: this.number(),
      unit: this.belongsTo(Unit, 'unitId'),
      unitId: this.uid().nullable(),
      pilots: this.hasMany(Pilot, 'flightId'),
    }
  }
}
