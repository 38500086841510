import Vue from 'vue'
import Vuex from 'vuex'
import VuexORM from '@vuex-orm/core'
import Unit from './models/Unit'
import Flight from './models/Flight'
import Pilot from './models/Pilot'
import Plane from './models/Plane'
import EmitterPlugin from './plugins/EmitterPlugin'
import packageJSON from '../../package.json'

Vue.use(Vuex)
VuexORM.use(EmitterPlugin)

const database = new VuexORM.Database()
database.register(Unit)
database.register(Flight)
database.register(Pilot)
database.register(Plane)

export default new Vuex.Store({
  state: {
    userName: '',
    appVersion: packageJSON.version,
    nodeEnv: process.env.NODE_ENV,
  },
  mutations: {
    loginUser(state, userName) {
      state.userName = userName
    }
  },
  getters: {
    appVersion: state => state.appVersion,
    runtimeEnvironment: state => state.nodeEnv,
    userLoggedIn: state => state.userName.length > 0,
    userName: state => state.userName
  },
  plugins: [VuexORM.install(database)],
})
