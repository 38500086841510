<template>
  <div id="StateOverlay">
    <p class="stateText">{{ text }}</p>
  </div>
</template>

<script>
export default {
  name: 'StateOverlay',
  props: ['text'],
}
</script>

<style scoped>
#StateOverlay {
  background: #000;
}

.stateText {
  text-align: center;
  font-weight: bold;
  opacity: 0.3;
  text-transform: uppercase;
  display: block;
  user-select: none;
  position: fixed;
  top: 50%;
  width: 100%;
  transform: translateY(-50%);
}
</style>
