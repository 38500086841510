<template>
  <div id="WPN">
    <state-overlay text="Coming soon" />
  </div>
</template>

<script>
import StateOverlay from '../components/StateOverlay'

export default {
  name: 'WPN',
  components: { StateOverlay },
}
</script>

<style scoped>
</style>
