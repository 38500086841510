import BaseModel from './BaseModel'
import Pilot from './Pilot'

export default class Plane extends BaseModel {
  static entity = 'planes'
  static backendModel = 'plane'

  static primaryKey = 'id'
  static fieldsToEmit = ['id', 'inGameId', 'name', 'fuel']

  static fields() {
    return {
      id: this.uid(),
      inGameId: this.string(),
      name: this.string(''),
      fuel: this.number(0),
      pilots: this.hasMany(Pilot, 'pilotId'),
    }
  }

  get fuelPercentage() {
    return `${this.fuel}%`
  }
}
