<template>
  <div id="app">
    <div class="userActionMenu" v-if="this.userActionMenuOpen">
      <h1>{{ this.userName }}</h1>
      <ul>
        <li><a @click="this.resetState">Reset database</a></li>
        <li><a class="logout" :href="`${this.$apiEndpoint}/auth/logout`">Logout</a></li>
      </ul>
    </div>
    <navigation class="topNavigation" :items="pages">
      <a class="loginBtn" v-if="!this.userLoggedIn" :href="`${this.$apiEndpoint}/auth/login`">
        <font-awesome-icon icon="sign-in-alt"/>
      </a>
      <a class="userBtn" v-if="this.userLoggedIn" @click="this.toggleUserActionMenu">
        <font-awesome-icon icon="user"/>
      </a>
    </navigation>
    <router-view v-if="dataLoaded" class="content" />
    <navigation class="bottomNavigation" :items="actions">
      <button :disabled="this.emptyFlights.length === 0" v-if="this.userLoggedIn" class="deleteFlightsBtn" @click="this.deleteEmptyFlights">
        <font-awesome-icon icon="trash-alt"/> Delete empty flights
      </button>
      <a class="version" href="https://github.com/8th-SFG-TITAN-Platoon/sts-app/blob/master/CHANGELOG.md" target="_blank">Sotac Tablet System v{{$store.getters.appVersion}}</a>
    </navigation>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import Navigation from './components/Navigation'
import Unit from './store/models/Unit'
import Flight from './store/models/Flight'
import Pilot from './store/models/Pilot'
import Plane from './store/models/Plane'

const pages = [
  {
    route: 'Home',
    name: 'Home',
  },
  {
    route: 'WPN',
    name: 'WPN',
  },
]
const actions = []

export default {
  name: 'App',
  data() {
    return {
      pages,
      actions,
      dataLoaded: false,
      userActionMenuOpen: false,
    }
  },
  async beforeMount() {
    window.io = this.$socket.client

    await fetch(`${this.$apiEndpoint}/auth`, { credentials: 'include' })
      .then((response) => {
        if (!response.status === 200) return

        response.json().then(({ discordName }) => {
          this.$store.commit("loginUser", discordName)
        })
      })

    await fetch(`${this.$apiEndpoint}/api/unit?populate=flights`)
      .then(r => r.json())
      .then(data => Unit.create({ data }))

    await fetch(`${this.$apiEndpoint}/api/flight?populate=pilots`)
      .then(r => r.json())
      .then(data => Flight.create({ data }))

    await fetch(`${this.$apiEndpoint}/api/pilot?populate=plane`)
      .then(r => r.json())
      .then(data => Pilot.create({ data }))

    await fetch(`${this.$apiEndpoint}/api/plane`)
      .then(r => r.json())
      .then(data => Plane.create({ data }))

    this.dataLoaded = true
  },
  sockets: {
    save({ model, id, data }) {
      const Model = this.getModelByName(model)
      const newData = Model.getRefRelationship(data)
      Model.insertOrUpdate({
        where: id,
        data: newData,
      })
    },
    delete({ model, id }) {
      const Model = this.getModelByName(model)
      Model.delete({ id })
    },
    forceRefresh() {
      window.location.reload()
    }
  },
  computed: {
    ...mapGetters([
      'userLoggedIn',
      'userName'
    ]),
    emptyFlights() {
      return Flight.query().hasNot('pilots').get()
    }
  },
  methods: {
    toggleUserActionMenu() {
      this.userActionMenuOpen = !this.userActionMenuOpen
    },
    resetState() {
      let userInput = null
      const runtimeEnv = this.$store.getters.runtimeEnvironment

      if (runtimeEnv === 'production') {
        userInput = prompt('Are you sure you want to reset the state? This will get rid of all data stored in the database.')
      } else if (runtimeEnv === 'development') {
        if (confirm('Are you sure you want to reset the state? This will get rid of all data stored in the database.')) {
          userInput = 'delete'
        }
      } else {
        return
      }

      if (userInput === 'delete') {
        this.$socket.client.emit('resetState', () => {
          window.location.reload()
        })
      }
    },
    async deleteEmptyFlights() {
      if (confirm('Delete empty flights?')) {
        const emptyFlights = Flight.query().hasNot('pilots').get();
        const deletePromises = emptyFlights.map((flight) => Flight.delete({
          id: flight.$id,
          shouldEmit: true
        }))
        await Promise.all(deletePromises)
      }
    },
    getModelByName(name) {
      const lowercase = (string = '') => [...string].map(
        (char, index) => (index ? char : char.toLowerCase()),
      ).join('')
      return this.$store.$db().model(`${lowercase(name)}s`)
    },
  },
  components: { Navigation },
}
</script>

<style scoped>
#app {
  height: 100%;
}

.userActionMenu {
  position: absolute;
  z-index: 999;
  background: #003000;
  border: 3px solid #7BE580;
  top: 60px;
  right: 6px;
}

.userActionMenu ul {
  list-style: none;
  margin: 0;
  padding: 0 0 10px 0;
}

.userActionMenu li a {
  display: block;
  padding: 5px 20px;
  text-decoration: none;
  color: inherit;
  cursor: pointer;
}

.userActionMenu li a.logout {
  color: red;
}

.userActionMenu li:hover {
  background: #000;
}

.userActionMenu h1 {
  margin: 0;
  padding: 10px 20px 0px 20px;
}

.content {
  padding: 70px 20px;
  padding-bottom: calc(env(safe-area-inset-bottom) + 70px);
  min-height: 100%;
  box-sizing: border-box;
}

.topNavigation {
  top: 0;
  left: 0;
  position: fixed;
}

.bottomNavigation {
  bottom: 0;
  left: 0;
  position: fixed;
  padding-bottom: env(safe-area-inset-bottom)
}

.version {
  flex-grow: 1;
  text-decoration: none;
  color: inherit;
  padding: 0 15px;
  font-weight: bold;
  margin: 0;
  line-height: 50px;
  text-align: right;
}

.userBtn, .loginBtn {
  text-align: center;
  font-size: 25px;
  color: inherit;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
}

.userBtn svg,
.loginBtn svg {
  align-self: center;
}

.deleteFlightsBtn {
  border: 3px solid red;
  background: darkred;
  color: #fff;
  padding: 0 10px;
  height: 35px;
  cursor: pointer;
  align-self: center;
  margin-left: 15px;
  font-family: "Avenir", Helvetica, Arial, sans-serif;
  font-weight: 700;
}

.deleteFlightsBtn:disabled {
  background: #494949;
  border-color: #272727;
  color: #272727;
  cursor: default;
}
</style>
