<template>
  <div id="Navigation" :class="{ noItems: items.length === 0 }">
    <div class="navigation">
      <ul>
        <li v-for="item in items" :key="item.name">
          <router-link :to="{ name: item.route }">{{ item.name }}</router-link>
        </li>
      </ul>
    </div>
    <div class="subnav">
      <slot></slot>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Navigation',
  props: {
    items: {
      type: Array,
      required: true,
      default() {
        return [
          {
            name: 'Home',
            route: 'Home',
          },
        ]
      },
    },
  },
}
</script>

<style scoped>
#Navigation {
  width: 100%;
  height: 50px;
  background: #003000;
  border-top: 3px solid;
  border-bottom: 3px solid;
  border-color: #7BE580;
  z-index: 99;
  white-space: nowrap;
}

.navigation {
  margin-right: 50px;
}

.navigation ul {
  -webkit-overflow-scrolling: touch;
  list-style: none;
  padding: 0;
  margin: 0;
  height: 100%;
  overflow-x: auto;
}

.navigation li {
  display: inline-block;
  height: 100%;
}

.navigation li a {
  display: block;
  line-height: 50px;
  text-align: center;
  text-decoration: none;
  color: #7BE580;
  text-transform: uppercase;
  font-weight: bold;
  background: #003000;
  padding: 0 20px;
  border-right: 3px solid #7BE580;
}

.navigation li a.router-link-exact-active {
  background: #008701;
}

.subnav {
  width: 50px;
  height: 100%;
  position: absolute;
  top: 0;
  right: 0;
  box-shadow: -36px 0px 34px -10px rgba(0,0,0,1);
  display: flex;
  align-content: flex-end;
  justify-content: space-between;
}

.noItems .subnav {
  width: 100%;
}
</style>
