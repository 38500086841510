/* eslint-disable no-param-reassign */
import io from '../../IoClient'

export default {
  install(components) {
    const {
      Model,
    } = components

    function getModelAsJSON(data) {
      return this.fieldsToEmit.reduce((json, field) => {
        if (Object.keys(data).includes(field)) {
          json[field] = data[field]
        }
        return json
      }, {})
    }

    function emitSave(payload, callback) {
      if (payload.shouldEmit) {
        return new Promise((resolve, reject) => {
          io().emit('save', {
            model: this.backendModel,
            id: payload.where,
            data: getModelAsJSON.call(this, payload.data),
          }, (backendId) => {
            if (backendId) {
              resolve(backendId)
            } else {
              reject('Could not be saved on the server')
            }
          })
        }).then((backendId) => {
          // If a new entity is created, the id should be retrieved from the backend.
          // eslint-disable-next-line no-underscore-dangle
          payload.data.id = backendId
          return callback.call(this, payload)
        })
      }
      return callback.call(this, payload)
    }

    function emitDelete(payload, callback) {
      if (payload.shouldEmit) {
        return new Promise((resolve, reject) => {
          io().emit('delete', {
            model: this.backendModel,
            id: payload.id,
          }, (backendId) => {
            if (backendId) {
              resolve(backendId)
            } else {
              reject('Could not be saved on the server')
            }
          })
        }).then(backendId => callback.call(this, backendId))
      }

      return callback.call(this, payload.id)
    }

    const originalUpdate = Model.update
    Model.update = function updateModel(payload) {
      return emitSave.call(this, payload, originalUpdate)
    }

    const originalInsert = Model.insert
    Model.insert = function insertModel(payload) {
      return emitSave.call(this, payload, originalInsert)
    }

    const originalInsertOrUpdate = Model.insertOrUpdate
    Model.insertOrUpdate = function insertOrUpdateModel(payload) {
      return emitSave.call(this, payload, originalInsertOrUpdate)
    }

    const originalDelete = Model.delete
    Model.delete = function deleteModel(payload) {
      return emitDelete.call(this, payload, originalDelete)
    }
  },
}
